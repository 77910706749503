<!-- 
    --------------------------
    |       INFORMATION      |
    --------------------------
    - XValue is PValue for Risk and FValue for RAMS
    - xLabel is Probability for Risk and Frequence for RAMS - being set in relation to what the source is
-->
<script setup>
    import { ref, reactive, onMounted, watch, onUnmounted, computed } from 'vue';
    import { getOrCreateDataObject, deleteDataObject } from 'o365-dataobject';
    import OMatrixCellData from 'risk.vue.components.matrix.MatrixCellData.vue';
    import { API } from 'o365-modules';
    
    const props = defineProps({
        "Source": String,
        "Type": String,
        "RiskIDs": Object,
        "isSetupApp": {
            type: Boolean, 
            default: false
        }, 
        "isDetailApp": {
            type: Boolean, 
            default: false
        },
        "DetailAppAssessments": { // Contains current row of Consequence-assessment and Probability-assessment from risk detail app
            type: Array,
            default: () => []
        },
        "EditEnabled": {
            type: Boolean,
            default: false
        },
        
        // From toolbar-comp
        "MatrixSize": String, 
        "ShowProbabilityValue": Boolean, 
        "ShowProbabilityText": Boolean, 
        "ShowProbabilityPercent": Boolean, 
        "ShowConsequenceText": Boolean,
        "ShowConsequenceValue" :{
            type: Boolean,
            default: true
        },
        "ConsequenceArea": {
            type: Object, 
            default: { id: "null", name: "Overall" }
        },
        // IF added in edit mode matrix selection tooltip apear from right side 
        "EditSide": {
            type: Boolean,
            default: null
            },
        "hideConsequenceLable": {
            type: Boolean,
            default: false
        },
        "hideProbabilityLable": {
            type: Boolean,
            default: false
        },
        "IsStatusElement":{
            type: Boolean,
            defailt: false
        },
        "orgUnitId": {
            type: Number,
            default: null
        }
    });

    let xLabel = ref("");
    let maxXValue = ref(0);
    let maxCValue = ref(0);
    let componentKey = ref(1)
    let consequence_values = reactive([]);
    let matrix_setup_data = reactive([]);
    let matrix_cells  = reactive ([]);
    const matrix_populated = ref(false); 
    const showSpinner = ref(false);

    const sourceIsRisk = computed(() => {
        return props.Source === 'Risk';
    });

    const local_dsRiskConsequences = getOrCreateDataObject({
        id: 'dsRiskConsequences',
        viewName: 'atbv_RiskManager_Consequences', maxRecords: -1,
        loadRecents: false,
        disableLayouts: true,
        fields: [
            { name: "Consequence", type: "string"}, 
            { name: "Value", type: "int", sortOrder: "1", sortDirection: "asc"},
            { name: "ConsequenceArea_ID", type: "int"},
            { name: "UncertaintyType", type: "string"},
            { name: "ValueAndConsequence", type: "string"},
            { name: "FromPercentage", type: "decimal"},
            { name: "ToPercentage", type: "decimal"}
        ]
    });

    onMounted(() => {
        populateMatrixArraysAndDS(props.Source, props.Type, props.orgUnitId);

        if(props.MatrixSize !== 'table-matrix-md') { // Needs this because matrixsize needs to be changed if it is sent in to the comp manually 
            changeMatrixSize('table-matrix-md', props.MatrixSize, false); 
        }
        if(props.ConsequenceArea.id &&  props.ConsequenceArea.name !== 'Overall'){

            local_dsRiskConsequences.recordSource.whereClause = "ConsequenceArea_ID = " + props.ConsequenceArea.id;
            local_dsRiskConsequences.load();

        }
    });
    
    onUnmounted(() => {
        deleteDataObject(local_dsRiskConsequences.id, local_dsRiskConsequences.appId);
    });

    const populateConsequenceValues = () => { // This function is for setting the default-values to the consequence-"row". 1-5 
        for(let i = 0; i < maxCValue.value; i++) {
            consequence_values.push({ Value: i+1, Consequence: null});
        }
        matrix_populated.value = true;
    }

    const populateMatrixArraysAndDS = async (pSource, pType, pOrgUnitID) => {
        // Resetting arrays and variables
        matrix_setup_data.length = 0;
        matrix_cells.length = 0;
        matrix_populated.value = false;
        consequence_values.length = 0;

        if(pSource == 'Risk') {
            xLabel.value = "Probability";
            
            await dsMatrixCellsOrgUnits.load();

            const viewName = dsMatrixCellsOrgUnits.data.length > 0 ? 'aviw_RiskManager_MatrixCellsFieldNamesOrgUnits' : 'aviw_RiskManager_MatrixCellsFieldNames';

            // One time request for the riskmatrix setup data
            const json_request = await API.request({
                requestInfo: `/api/data/${viewName}`,
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({
                    'viewName': viewName,
                    'distinctRows': false,
                    'skip': 0,
                    'fields': [
                        { name: "UncertaintyType", type: "string"}, 
                        { name: "PValue", type: "int"},
                        { name: "CValue", type: "int"},
                        { name: "Color", type: "string"},
                        { name: "Probability", type: "string"},
                        { name: "TextColor", type: "string"},
                        { name: "ProbabilityAndPercent", type: "string"},
                        { name: "ProbabilityPercentArea", type: "string"}
                    ],
                    'loadRecents': false,
                    'whereClause': "UncertaintyType = '" + pType + "'" + 
                        (viewName === 'aviw_RiskManager_MatrixCellsFieldNamesOrgUnits' ? " AND DescendantOrgUnit_ID = '" + pOrgUnitID + "'" : ""),
                    'masterDetailString': null,
                    'filterString': null,
                    'operation': 'retrieve'
                })
            });

            json_request.forEach(
                // Creating local array with values from json-request
                matrix => matrix_setup_data.push({
                    Type:                   matrix.UncertaintyType, 
                    XValue:                 matrix.PValue, 
                    CValue:                 matrix.CValue, 
                    Color:                  matrix.Color, 
                    Probability:            matrix.Probability, 
                    TextColor:              matrix.TextColor, 
                    ProbabilityAndPercent:  matrix.ProbabilityAndPercent,
                    ProbabilityPercentArea: matrix.ProbabilityPercentArea
                })   
            )
        } else if(pSource == 'Rams') {
            xLabel.value = "Frequence";

            // One time request for the ramsmatrix setup data
            const json_request = await API.request({
                requestInfo: '/api/data/atbv_RAMS_Matrix',
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({
                    'viewName': 'atbv_RAMS_Matrix',
                    'distinctRows': false,
                    'skip': 0,
                    'fields': [
                        { name: "Type", type: "string"}, 
                        { name: "FValue", type: "int"},
                        { name: "CValue", type: "int"},
                        { name: "Color", type: "string"},
                        { name: "Criticality", type: "string"},
                        { name: "TextColor", type: "string"},
                        { name: "CalculatedCriticality", type: "string"}
                    ],
                    'loadRecents': false,
                    'masterDetailString': null,
                    'filterString': null,
                    'operation': 'retrieve'
                })
            });

            json_request.forEach(
                // Creating local array with values from json-request
                matrix => matrix_setup_data.push({
                    Type:                   matrix.Type, 
                    XValue:                 matrix.FValue, 
                    CValue:                 matrix.CValue, 
                    Color:                  matrix.Color, 
                    Criticality:            matrix.Criticality, 
                    TextColor:              matrix.TextColor, 
                    CalculatedCriticality:  matrix.CalculatedCriticality
                })
            )
        }
        buildMatrix();
    }

    const buildMatrix = () => {
        maxXValue.value = getMaxXValue();
        maxCValue.value = getMaxCValue();
        
        for (let x = maxXValue.value; x >= 1; x--) {
            const row = [];
            
            for (let c = 1; c <= maxCValue.value; c++) {
                const item = matrix_setup_data.find(
                    (matrixItem) => matrixItem.XValue === x && matrixItem.CValue === c
                );

                row.push(item ? { ...item } : null);
            }
            
            matrix_cells.push(row);
        }
        populateConsequenceValues(); // Calling this for getting consequencevalues in matrix
    };

    const getMaxXValue = () => {
        return Math.max(...matrix_setup_data.map((item) => item.XValue));
    };

    const getMaxCValue = () => {
        return Math.max(...matrix_setup_data.map((item) => item.CValue));
    };

    watch(() => props.MatrixSize, (newValue, oldValue) => {
        changeMatrixSize(oldValue, newValue, true)
    })

    watch(() => props.Type, (newVal, oldVal) => {
        if(newVal !== oldVal) { 
            populateMatrixArraysAndDS(props.Source, props.Type, props.orgUnitId);
        }
    })

    watch(() => props.orgUnitId, (newVal, oldVal) => {
        if(newVal !== oldVal) { 
            populateMatrixArraysAndDS(props.Source, props.Type, props.orgUnitId);
        }
    })

    const changeMatrixSize = (currentMatrixSize, newMatrixSize, withForceRender) => {
        if(currentMatrixSize == null || currentMatrixSize == undefined || currentMatrixSize == "") {
            return;
        }

        const elements = document.querySelectorAll('.table-matrix');
        for(let i = 0; i < elements.length; i++) {
            elements[i].classList?.replace(currentMatrixSize, newMatrixSize)
        }

        if(withForceRender) {
            forceRerender();
        }
    }

    watch(() => props.ConsequenceArea, (newValue) => {
        consequence_values.splice(0, consequence_values.length);
        if(newValue.id === 'null') {
            populateConsequenceValues();
            forceRerender();
            return;
        }

        local_dsRiskConsequences.recordSource.whereClause = "ConsequenceArea_ID = " + newValue.id;
        local_dsRiskConsequences.load();
    });

    watch(() => props.RiskIDs, (newValue, oldValue) => {
        if(newValue.length !== oldValue.length) {
            forceRerender();
        }
    });

    local_dsRiskConsequences.on('DataLoaded', () => {
        local_dsRiskConsequences.data.forEach(el => {
            consequence_values.push({
                Value: el.item.Value,
                Consequence: el.item.Consequence,
                Type: el.item.UncertaintyType,
            });
        });

        forceRerender();
    });

    const forceRerender = () => {
        componentKey.value += 1;
    };

    //Drop down positioning and functionality
    const dropElm = ref(null);
    const newDropCValue = ref(null);
    const newDropPValue = ref(null);
    const dTop = ref(null);
    const dLeft = ref(null);
    
    function dropDownToggle(emittedValues){
        const dropDowns = document.querySelectorAll('.setCurrAssessment');
            
            dropDowns.forEach(element => {
                element.classList?.remove('show');
            });
        if(emittedValues.MatrixCValue != newDropCValue.value || emittedValues.MatrixPValue != newDropPValue.value || !dropElm.value.classList?.contains('show')){
            newDropCValue.value = emittedValues.MatrixCValue;
            newDropPValue.value = emittedValues.MatrixPValue;

            // LEFT SIDE
            if(!props.EditSide){
                dTop.value = emittedValues.CellTopPosition + 30;
                dLeft.value = emittedValues.CellLeftPosition - 187;
            }
            // Right SIDE
            else{
                dTop.value = emittedValues.CellTopPosition + 30;
                dLeft.value = emittedValues.CellLeftPosition;

            }
            dropElm.value.classList?.remove('show')
            dropElm.value.classList?.add('show')
        }else{
            dropElm.value.classList?.remove('show')
        }

        
    }
    // Hides the drop down when clicked outside the cell
    window.addEventListener("click", function(event) {
        if (!event?.target?.classList?.contains('boxCell') ) {
            dropElm.value?.classList?.remove('show')
        }
    });

    const setCurrentAssessment = () => {
        props.DetailAppAssessments[0].Value = newDropCValue.value; // Setting current consequence assessment value to the matrix's C cell value
        props.DetailAppAssessments[1].current.Value = newDropPValue.value; // Setting current probability assessment value to the matrix's P cell value
        props.DetailAppAssessments[2].save(); // -> dsConsAssessments.save()
        props.DetailAppAssessments[1].save(); // -> dsProbAssessments.save()
    }


const consequenceValues = computed(() => {
    if(props.ConsequenceArea.id == 'null'){
        return consequence_values
    }
    else{
        return consequence_values.filter((item)=>  item.Type  == props.Type);
    }
});

const dsMatrixCellsOrgUnits = getOrCreateDataObject({
    id: 'dsMatrixCellsOrgUnits',
    viewName: 'aviw_RiskManager_MatrixCellsFieldNamesOrgUnits',
    maxRecords: 50,
    fields: [{ name: "ID", type: "number"}]
});

dsMatrixCellsOrgUnits.recordSource.whereClause = "DescendantOrgUnit_ID = " + props.orgUnitId + " AND UncertaintyType = " + `'${props.Type}'`;

</script>

<template>
    <div class="position-relative main-div">
        <table class="table-matrix table-matrix-md">
            <tbody v-if="matrix_populated">
                <tr >
                    <td></td>
                    <td v-if="sourceIsRisk" class="cLabel" :colspan="maxCValue">{{  hideConsequenceLable != true && hideConsequenceLable != 'true' ? $t('Consequence') : '' }}</td> <!-- RISK -->
                    <td v-else class="matrix-consequence-text" v-for="conseq in consequence_values" :key="conseq.Value"> <!-- RAMS -->
                        <span>{{ ShowConsequenceValue == false ? '' : conseq.Value }}</span>
                        <br>
                        <span v-if="ShowConsequenceText">{{ $t(conseq.Consequence) }}</span>
                    </td>
                    <td :rowspan="maxXValue+1" > <!-- +1 because of "invisable" td -->
                        <div class="xLabel">
                             {{ hideProbabilityLable != true && hideProbabilityLable != 'true' ?  (xLabel === "Probability" ?  $t('Probability') : $t('Frequence'))  : '' }}
                        </div>
                    </td>
                </tr>
                
                <tr v-for="(row, rowIndex) in matrix_cells" :key="rowIndex">
                    <td class="matrix-probability-text">
                        <span v-if="ShowProbabilityValue">{{ row[0].XValue }}</span>
                        <span v-if="ShowProbabilityValue && ShowProbabilityText"> - </span>
                        <span v-if="ShowProbabilityText">{{ $t(row[0].Probability) }}</span>
                        <br>
                        <span v-if="ShowProbabilityPercent">({{ $t(row[0].ProbabilityPercentArea) }})</span>
                    </td>
                    <td class="cell" :data-x-value="cell.XValue" :data-c-value="cell.CValue" v-for="(cell, cellIndex) in row" :key="cellIndex" :style="{ backgroundColor: cell.Color, color: cell.TextColor }">
                        <!-- Celldata for setup app's -->
                        <span 
                            title="This is a test" 
                            style="cursor: help; font-size:1rem;"
                            class="fw-bold" 
                            v-if="isSetupApp"
                        >
                            Test
                        </span>
                        <!-- 
                            * Celldata for riskregisters Matrix-tab 
                            - Using v-show and v-if together because MatrixCell component needs to be loaded for it to emit toggleSpinner 
                        -->
                        <OMatrixCellData 
                            v-if="!props.isDetailApp"
                            v-show="!showSpinner"
                            @toggleSpinner="(toggleSpinner) => showSpinner = toggleSpinner"
                            :key="componentKey"
                            :Source="props.Source"
                            :UncertaintyType="props.Type"
                            :RiskIDs="RiskIDs"
                            :MatrixSize="MatrixSize"
                            :ConsequenceArea_ID="ConsequenceArea.id"
                            :MatrixCValue="cell.CValue"
                            :MatrixPValue="cell.XValue"
                            :MaxCellValue="maxXValue"
                            :ButtonColor="cell.TextColor"
                            @dataCell="dropDownToggle"
                            :IsStatusElement="props.IsStatusElement"
                            :LastCellLoaded="maxCValue == cell.CValue && maxXValue == cell.XValue? true : false "
                        />
                        <!-- 
                            * Celldata for risk detail app 
                            - Using v-show and v-if together because MatrixCell component needs to be loaded for it to emit toggleSpinner     
                        -->
                        <OMatrixCellData 
                            v-if="props.isDetailApp"
                            v-show="!showSpinner"
                            @toggleSpinner="(toggleSpinner) => showSpinner = toggleSpinner"
                            :key="componentKey"
                            :Source="props.Source"
                            :UncertaintyType="props.Type"
                            :RiskIDs="RiskIDs"
                            :MatrixSize="MatrixSize"
                            :ConsequenceArea_ID="ConsequenceArea.id"
                            :MatrixCValue="cell.CValue"
                            :MatrixPValue="cell.XValue"
                            :MaxCellValue="maxXValue"
                            :EditEnabled="props.EditEnabled"
                            :DetailAppAssessments="props.DetailAppAssessments"
                            :ButtonColor="cell.TextColor"
                            @dataCell="dropDownToggle"
                            :IsStatusElement="props.IsStatusElement"
                            :LastCellLoaded="maxCValue == cell.CValue && maxXValue == cell.XValue? true : false "
                        />
                    </td>
                </tr>
                <tr v-if="sourceIsRisk"> <!-- RISK -->
                    <td></td>
                    <td class="matrix-consequence-text" v-for="conseq in consequenceValues" :key="conseq.Value">
                        <span>{{ ShowConsequenceValue == false ? '' : conseq.Value }}</span>
                        <br>
                        <span v-if="ShowConsequenceText">{{ $t(conseq.Consequence) }}</span>
                    </td>
                </tr>
                <tr v-else> <!-- RAMS -->
                    <td></td>
                    <td :colspan="maxCValue"><div class="ramsCLabel">{{ $t('Consequence') }}</div></td>
                </tr>
            </tbody>
        </table>

        <!-- Set current assessment dropdown showed when cell is clicked -->
        <div class="dropdown-menu position-absolute setCurrAssessment"  ref="dropElm" :style="{ top: dTop + 'px', left: dLeft + 'px' }">
            <h6 class="dropdown-header my-0" style="text-transform: inherit;">{{ $t('Probability and consequence') }}</h6>
            <button class="dropdown-item" @click="setCurrentAssessment">{{ $t('Set current assessment') }}</button>
        </div>

        <!-- Spinner overlay -->
        <div class="spinner-overlay position-absolute d-flex justify-content-center align-items-center" v-if="showSpinner">
            <div class="spinner-border" style="width: 6rem; height: 6rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>

<style scoped>
    /* Fade in of main matrix-element */
    .main-div {
        opacity: 0;
        animation: fadeInAnimation 0.5s ease-in-out forwards;
    }

    @keyframes fadeInAnimation {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Spinner overlay */
    .spinner-overlay {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
    }

    /* Common for all matrix sizes */
    .table-matrix {
        text-align: center;
    }

    .table-matrix td {
        border: 0.5px solid lightgray;
    }

    .table-matrix td.matrix-probability-text {
        font-size: 0.8em;
        border: none !important;
        text-align: left;
        padding-left: 6px;
        padding-right: 6px;
        white-space: nowrap;
    }

    .table-matrix td.matrix-consequence-text {
        vertical-align: top; 
        font-size: 0.8em;
        border: none !important;
    }

    .table-matrix tr td:first-child {
        text-align: left;
        border: none !important;
    }

    .table-matrix tr:last-child td,
    .table-matrix tr:first-child td {
        border: none !important;
    }

    .table-matrix td.cell:hover {
        -webkit-box-shadow: 2px 4px 22px 2px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 2px 4px 22px 2px rgba(0, 0, 0, 0.75);
        box-shadow: 2px 4px 22px 2px rgba(0, 0, 0, 0.75);
    }
    
    .cLabel {
        height: 26.5px;
        text-align: left;
        font-size: 1rem;
    }

    .xLabel {
        display: inline-table;
        writing-mode: tb-rl;
        font-size: 1rem;
        padding-left: 6px;
    }

    .ramsCLabel {
        display: inline-table;
        font-size: 1rem;
        padding-left: 6px;
    }

    /* Matrix - extra small */
    .table-matrix-xs td.cell {
        width: 25px;
        height: 25px;
        border: 0.5px solid lightgray;
        font-weight: bold;

        /*max-height: 25px;
        min-width: 25px;*/
    }

    /* Matrix - small*/
    .table-matrix-sm td.cell {
        width: 50px;
        height: 50px;
        border: 0.5px solid lightgray;
        font-weight: bold;

        max-height: 50px;
        min-width: 50px;
    }

    /* Matrix - medium */
    .table-matrix-md td.cell {
        width: 70px;
        height: 70px;
        border: 0.5px solid lightgray;
        font-weight: bold;

        max-height: 70px;
        min-width: 70px;

        overflow: auto;
    }

    /* Matrix - large */
    .table-matrix-lg td.cell {
        width: 100px;
        height: 100px;
        border: 0.5px solid lightgray;
        font-weight: bold;

        max-height: 100px;
        min-width: 100px; 
    }
</style>